/* *{
  border:solid 2px red;
} */

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
:root{
  --color-blue:#244363;
  --color-black: #080706;
  --color-yellow: #FFE084;
  --color-bluesky: #D3E9F6;
  --color-gray: #CECDCD;
  --color-white: #FFFFFF;
  --color-darkgray: #747474;

  --color-seablue: #2B3984;
  --color-deepblue: #0A175C;
  --color-aqua: #6BCFC8;
  --color-pink: #E74066;
  --color-silver: #E9E9E9;

  --color-rgb-seablue: 43, 57, 132;
  --color-rgb-blue:35, 66, 97;
  --color-rgb-black: 8, 7, 6;
  --color-rgb-yellow: 255, 224, 132;
  --color-rgb-bluesky: 211, 233, 246;
  --color-rgb-gray: 206, 205, 205;
  --color-rgb-white: 255, 255, 255;

  --color-rgb-deepblue: 10, 23, 92;
  --color-rgb-aqua: 107, 207, 200;
  --color-rgb-pink: 231, 64, 102;
  --color-rgb-silver: 233, 233, 233;
  --color-rgb-darkgray: 116, 116, 116;
}

body {
  /* margin: 0; */
  font-family: 'DM Sans' !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-nav .nav-link.active {
  color: var(--color-deepblue) !important;
}

.navbar-collapse {
  /* background-color: rgba(var(--color-rgb-aqua),0.5); */
  background-color: var(--color-aqua);
}

.dropdown-menu.show {
  /* background-color: rgba(var(--color-rgb-aqua),0.5); */
  background-color: var(--color-aqua);
}

/* Carousel */
.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: var(--color-white) !important;
  color: var(--color-black);
  height: 100%;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  background: var(--color-white);
  margin-top: -5%;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid var(--color-silver);
  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: var(--color-black);
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: var(--color-gray);
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: var(--color-black);
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: var(--color-gray);
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: var(--color-gray);
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: var(--color-deepblue) !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: var(--color-black) !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
  background-color: var(--color-deepblue) !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
    top: none !important;
    background-color: var(--color-deepblue) !important;
    font-size: 26px;
    bottom: none !important;
    margin: none !important;
    padding: 5px;
  }
  .myCarousel {
    background: var(--color-white);
    margin-top: -5%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid var(--color-silver);
    height: 360px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}


.img-thumbnail {
  border: none !important;
}

.ImageSpinner{
  margin-top: 18rem;
  width: 180px;
  height: 140px;
}

.textLoading {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 30px !important;
  color:var(--color-deepblue);
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Poppins-Bold.woff") format("woff"); 
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/Poppins-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Poppins-Medium.woff") format("woff"); 
}

@font-face {
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Anton-Regular.woff") format("woff"); 
}

@media only screen and (min-width: 320px) and (max-width: 480px) { /*Mobile devices*/
  .navbar-toggler{
    margin-right: 0.5rem;
  }  
}

@media only screen and (min-width: 481px) and (max-width: 768px) { /*iPads, Tablets*/
  .navbar-toggler{
    margin-right: 0.5rem;
  }  
}

@media only screen and (min-width: 769px) and (max-width: 991px) { /*Small screens, laptops*/
  .navbar-toggler{
    margin-right: 0.5rem;
  }  
}
